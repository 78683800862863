<template>
  <div
    class="horizontal-layout self__registration"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
    style="height:inherit"
  >

    <!-- NAVBAR -->
    <b-navbar
      :style="{
        backgroundColor: navbarType === 'static' && scrolledTo && skin === 'light' ? 'blue' : null,
        boxShadow: navbarType === 'static' && scrolledTo ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px' : null,
      }"
      :toggleable="false"
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
      :class="{'fixed-top': $store.getters['app/currentBreakPoint'] !== 'xl'}"
    >
      <slot
        name="navbar"
      >
        <app-navbar-horizontal-layout-brand />
      </slot>
    </b-navbar>

    <div>
      <b-card
        header-tag="header"
      >
        <template #header>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="flat-primary"
            class="primary-button-with-background mr-3"
          >
            <feather-icon
              icon="GridIcon"
              class=""
              size="20"
            />
          </b-button>
          <h3 class="align-middle mr-auto">
            Valid Payment Cycle
          </h3>
        </template>
        <b-row class="time__slots">
          <b-col lg="6">
            <b-form-group
              label="Choose a Date"
              label-for="h-start"
              label-cols-md="3"
            >
              <b-input-group>
                <flat-pickr
                  id="h-event-start"
                  ref="datePicker"
                  v-model="date"
                  class="form-control flat-pickr-group"
                  :config="flatPickrConfig"
                  @on-change="generateCycle"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="CalendarIcon"
                    class="cursor-pointer"
                    data-toggle
                    size="18"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <p class="mb-0">
              This Cycle: {{ thisCycle }}
            </p>
            <p class="mb-0">
              Next Cycle: {{ nextCycle }}
            </p>
          </b-col>
        </b-row>
      </b-card>
      <div
        v-show="$store.state.appConfig.showLoader"
        class="axios-loader"
      >
        <loader />
      </div>

      <!-- Footer -->
      <footer
        class="footer footer-light has_save_bar"
      >
        <slot name="footer">
          <app-footer />
        </slot>
      </footer>
    </div>
  </div></template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'

import { onUnmounted } from '@vue/composition-api'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BFormGroup, BNavbar, BInputGroup, BInputGroupAppend, BCard, BButton,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useLayoutHorizontal from '@core/layouts/layout-horizontal/useLayoutHorizontal'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

import AppFooter from '../../layouts/components/AppFooter.vue'
import Loader from '../../layouts/horizontal/Loader.vue'
import AppNavbarHorizontalLayoutBrand from '../../layouts/horizontal/AppNavbarHorizontalLayoutBrand.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BNavbar,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    AppFooter,
    Loader,
    AppNavbarHorizontalLayoutBrand,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      date: '',
      thisCycle: '',
      nextCycle: '',
      flatPickrConfig: {
        wrap: true, enableTime: false, dateFormat: 'd/m/Y', disableMobile: true,
      },
    }
  },
  methods: {
    generateCycle() {
      const timezone = process.env.VUE_APP_TIME_ZONE
      const today = moment(this.date, 'DD/MM/YYYY').utcOffset(timezone).date()
      let thisCycleStartDate = today
      let thisCycleEndDate = today
      let nextCycleStartDate = today
      let nextCycleEndDate = today
      if (today > 15 && today <= 28) {
        const thisCycleStartStr = `${moment(this.date, 'DD/MM/YYYY').subtract(1, 'months').format('YYYY-MM')}-28`
        thisCycleStartDate = moment(thisCycleStartStr, 'YYYY-MM-DD').add(1, 'days').utcOffset(timezone)
        const thisCycleStartDay = thisCycleStartDate.clone().weekday()
        if (thisCycleStartDay === 0) {
          thisCycleStartDate = moment(thisCycleStartDate).subtract(1, 'days').utcOffset(timezone)
        } else if (thisCycleStartDay === 1) {
          thisCycleStartDate = moment(thisCycleStartDate).subtract(2, 'days').utcOffset(timezone)
        }

        const thisCycleEndStr = `${moment(this.date, 'DD/MM/YYYY').format('YYYY-MM')}-15`
        thisCycleEndDate = moment(thisCycleEndStr, 'YYYY-MM-DD').utcOffset(timezone)
        const thisCycleEndDay = thisCycleEndDate.clone().weekday()
        if (thisCycleEndDay === 0) {
          thisCycleEndDate = moment(thisCycleEndDate).subtract(2, 'days').utcOffset(timezone)
        } else if (thisCycleEndDay === 6) {
          thisCycleEndDate = moment(thisCycleEndDate).subtract(1, 'days').utcOffset(timezone)
        }

        const nextCycleStartStr = `${moment(this.date, 'DD/MM/YYYY').format('YYYY-MM')}-16`
        nextCycleStartDate = moment(nextCycleStartStr, 'YYYY-MM-DD').utcOffset(timezone)
        const nextCycleStartDay = nextCycleStartDate.clone().weekday()
        if (nextCycleStartDay === 0) {
          nextCycleStartDate = moment(nextCycleStartDate).subtract(1, 'days').utcOffset(timezone)
        } else if (nextCycleStartDay === 1) {
          nextCycleStartDate = moment(nextCycleStartDate).subtract(2, 'days').utcOffset(timezone)
        }

        const nextCycleEndStr = `${moment(this.date, 'DD/MM/YYYY').format('YYYY-MM')}-28`
        nextCycleEndDate = moment(nextCycleEndStr, 'YYYY-MM-DD').utcOffset(timezone)
        const nextCycleEndDay = nextCycleEndDate.clone().weekday()
        if (nextCycleEndDay === 0) {
          nextCycleEndDate = moment(nextCycleEndDate).subtract(2, 'days').utcOffset(timezone)
        } else if (nextCycleEndDay === 6) {
          nextCycleEndDate = moment(nextCycleEndDate).subtract(1, 'days').utcOffset(timezone)
        }
      } else if (today > 28 && today <= 31) {
        const thisCycleStartStr = `${moment(this.date, 'DD/MM/YYYY').format('YYYY-MM')}-16`
        thisCycleStartDate = moment(thisCycleStartStr, 'YYYY-MM-DD').utcOffset(timezone)
        const thisCycleStartDay = thisCycleStartDate.clone().weekday()
        if (thisCycleStartDay === 0) {
          thisCycleStartDate = moment(thisCycleStartDate).subtract(1, 'days').utcOffset(timezone)
        } else if (thisCycleStartDay === 1) {
          thisCycleStartDate = moment(thisCycleStartDate).subtract(2, 'days').utcOffset(timezone)
        }

        const thisCycleEndStr = `${moment(this.date, 'DD/MM/YYYY').format('YYYY-MM')}-28`
        thisCycleEndDate = moment(thisCycleEndStr, 'YYYY-MM-DD').utcOffset(timezone)
        const thisCycleEndDay = thisCycleEndDate.clone().weekday()
        if (thisCycleEndDay === 0) {
          thisCycleEndDate = moment(thisCycleEndDate).subtract(2, 'days').utcOffset(timezone)
        } else if (thisCycleEndDay === 6) {
          thisCycleEndDate = moment(thisCycleEndDate).subtract(1, 'days').utcOffset(timezone)
        }

        const nextCycleStartStr = `${moment(this.date, 'DD/MM/YYYY').format('YYYY-MM')}-28`
        nextCycleStartDate = moment(nextCycleStartStr, 'YYYY-MM-DD').add(1, 'days').utcOffset(timezone)
        const nextCycleStartDay = nextCycleStartDate.clone().weekday()
        if (nextCycleStartDay === 0) {
          nextCycleStartDate = moment(nextCycleStartDate).subtract(1, 'days').utcOffset(timezone)
        } else if (nextCycleStartDay === 1) {
          nextCycleStartDate = moment(nextCycleStartDate).subtract(2, 'days').utcOffset(timezone)
        }

        const nextCycleEndStr = `${moment(this.date, 'DD/MM/YYYY').add(1, 'months').format('YYYY-MM')}-15`
        nextCycleEndDate = moment(nextCycleEndStr, 'YYYY-MM-DD').utcOffset(timezone)
        const nextCycleEndDay = nextCycleEndDate.clone().weekday()
        if (nextCycleEndDay === 0) {
          nextCycleEndDate = moment(nextCycleEndDate).subtract(2, 'days').utcOffset(timezone)
        } else if (nextCycleEndDay === 6) {
          nextCycleEndDate = moment(nextCycleEndDate).subtract(1, 'days').utcOffset(timezone)
        }
      } else {
        const thisCycleStartStr = `${moment(this.date, 'DD/MM/YYYY').subtract(1, 'months').format('YYYY-MM')}-16`
        thisCycleStartDate = moment(thisCycleStartStr, 'YYYY-MM-DD').utcOffset(timezone)
        const thisCycleStartDay = thisCycleStartDate.clone().weekday()
        if (thisCycleStartDay === 0) {
          thisCycleStartDate = moment(thisCycleStartDate).subtract(1, 'days').utcOffset(timezone)
        } else if (thisCycleStartDay === 1) {
          thisCycleStartDate = moment(thisCycleStartDate).subtract(2, 'days').utcOffset(timezone)
        }

        const thisCycleEndStr = `${moment(this.date, 'DD/MM/YYYY').subtract(1, 'months').format('YYYY-MM')}-28`
        thisCycleEndDate = moment(thisCycleEndStr, 'YYYY-MM-DD').utcOffset(timezone)
        const thisCycleEndDay = thisCycleEndDate.clone().weekday()
        if (thisCycleEndDay === 0) {
          thisCycleEndDate = moment(thisCycleEndDate).subtract(2, 'days').utcOffset(timezone)
        } else if (thisCycleEndDay === 6) {
          thisCycleEndDate = moment(thisCycleEndDate).subtract(1, 'days').utcOffset(timezone)
        }

        const nextCycleStartStr = `${moment(this.date, 'DD/MM/YYYY').subtract(1, 'months').format('YYYY-MM')}-28`
        nextCycleStartDate = moment(nextCycleStartStr, 'YYYY-MM-DD').add(1, 'days').utcOffset(timezone)
        const nextCycleStartDay = nextCycleStartDate.clone().weekday()
        if (nextCycleStartDay === 0) {
          nextCycleStartDate = moment(nextCycleStartDate).subtract(1, 'days').utcOffset(timezone)
        } else if (nextCycleStartDay === 1) {
          nextCycleStartDate = moment(nextCycleStartDate).subtract(2, 'days').utcOffset(timezone)
        }

        const nextCycleEndStr = `${moment(this.date, 'DD/MM/YYYY').format('YYYY-MM')}-15`
        nextCycleEndDate = moment(nextCycleEndStr, 'YYYY-MM-DD').utcOffset(timezone)
        const nextCycleEndDay = nextCycleEndDate.clone().weekday()
        if (nextCycleEndDay === 0) {
          nextCycleEndDate = moment(nextCycleEndDate).subtract(2, 'days').utcOffset(timezone)
        } else if (nextCycleEndDay === 6) {
          nextCycleEndDate = moment(nextCycleEndDate).subtract(1, 'days').utcOffset(timezone)
        }
      }

      this.thisCycle = `${moment(thisCycleStartDate).format('DD/MM/YYYY')} - ${moment(thisCycleEndDate).format('DD/MM/YYYY')}`
      this.nextCycle = `${moment(nextCycleStartDate).format('DD/MM/YYYY')} - ${moment(nextCycleEndDate).format('DD/MM/YYYY')}`
    },
  },
  setup() {
    const {
      skin,
      navbarType,
      footerType,
      routerTransition,
      isNavMenuHidden,
    } = useAppConfig()

    // Vertical Menu
    const {
      isVerticalMenuActive, toggleVerticalMenuActive, overlayClasses, resizeHandler,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const {
      navbarMenuTypeClass,
      layoutClasses,
      footerTypeClass,
    } = useLayoutHorizontal(navbarType, footerType, isVerticalMenuActive)

    // Scroll Listener
    const { scrolledTo } = useScrollListener()

    return {
      // skin
      skin,

      // Layout
      layoutClasses,

      // Navbar
      navbarType,
      navbarMenuTypeClass,

      // Menu Hidden
      isNavMenuHidden,

      // Router Transition
      routerTransition,

      // Footer
      footerTypeClass,

      // Scroll Listeners
      scrolledTo,

      // Vertical Menu
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
